<template>
  <div class="create-registry">
    <PageLoader v-if="!pageLoad" />

    <div class="page-title d-flex py-2 justify-content-between">
      <h1 v-if="edit" class="h4 mb-0">
        Редактирование реестра платежей
        <span v-if="currentRegistry"
          >№ {{ currentRegistry.number }} от
          {{ currentRegistry.date | parseDate }}</span
        >
      </h1>
      <h1 v-else class="h4 mb-0">Создание реестра платежей</h1>

      <div class="d-flex align-items-center">
        <BButton class="ml-3" variant="warning" v-b-modal.confirm-delete
          >Удалить
        </BButton>
        <BButton class="ml-3" variant="success" @click="createRegistry(true)"
          >Сохранить
        </BButton>
        <BButton class="ml-3" variant="primary" @click="createRegistry()"
          >Отправить на согласование
        </BButton>
      </div>
    </div>

    <b-tabs v-model="tabIndex" card>
      <b-tab title="Информация">
        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Подрядчик
          </div>
          <div class="px-2 py-1 flex-grow-1">
            {{ user.contr_agent.name }}
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Поставщик
          </div>
          <div class="px-2 py-1 flex-grow-1">
            <!-- <b-form-select
              v-model="sendData.provider_contr_agent_id"
              :state="
                $v.sendData.$error
                  ? $v.sendData.provider_contr_agent_id.required
                  : null
              "
              :options="getContrAgentsOptions.slice(1)"
            /> -->

            <span v-if="isProvider">
              {{ getContrAgent ? getContrAgent.name : "" }}
            </span>
            <v-select
              v-else
              label="text"
              :class="{
                'v-select-error': $v.sendData.provider_contr_agent_id.$error,
              }"
              :state="
                $v.sendData.$error
                  ? $v.sendData.provider_contr_agent_id.required
                  : null
              "
              :appendToBody="true"
              :options="getContrAgentsOptions.slice(1)"
              :placeholder="
                getContrAgentsOptions
                  .slice(1)
                  .find((el) => el.value === sendData.provider_contr_agent_id)
                  ? getContrAgentsOptions
                      .slice(1)
                      .find(
                        (el) => el.value === sendData.provider_contr_agent_id
                      ).text
                  : ''
              "
              :value="
                getContrAgentsOptions
                  .slice(1)
                  .find((el) => el.value === sendData.provider_contr_agent_id)
              "
              v-on:input="
                (value) => (sendData.provider_contr_agent_id = value.value)
              "
              @change="
                (e) => {
                  console.log(e);
                }
              "
              @search="getContrAgentsOptionsByQuery"
            >
              <template #no-options> Нет результатов. </template>
            </v-select>
          </div>
        </div>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            № договора поставки
          </div>
          <div class="px-2 py-1 flex-grow-1">
            <b-form-select
              v-model="sendData.provider_contract_id"
              :state="
                $v.sendData.$error
                  ? $v.sendData.provider_contract_id.required
                  : null
              "
              :options="getProvidersContractsOptions.slice(1)"
            />
          </div>
        </div>

        <b-row>
          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Ответственный исполнитель
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <b-form-input
                  v-model="sendData.responsible_full_name"
                  :state="
                    $v.sendData.$error
                      ? $v.sendData.responsible_full_name.required
                      : null
                  "
                  trim
                />
              </div>
            </div>
          </b-col>

          <b-col cols="6">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Телефон
              </div>
              <div class="px-2 py-1 flex-grow-1">
                <b-form-input
                  v-model="sendData.responsible_phone"
                  :state="
                    $v.sendData.$error
                      ? $v.sendData.responsible_phone.required
                      : null
                  "
                  v-mask="'+7 (###) ###-##-##'"
                  trim
                />
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="d-flex table-bordered mb-1">
          <div
            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
            style="width: 250px"
          >
            Комментарий
          </div>
          <div class="px-2 py-1 flex-grow-1">
            <b-form-textarea
              v-model.trim="sendData.comment"
              :state="$v.sendData.$error ? $v.sendData.comment.required : null"
              placeholder="Текст комментария"
            />
          </div>
        </div>
      </b-tab>

      <b-tab title="Позиции">
        <CreateTable
          v-if="orders && orders.length"
          v-model="positions"
          :fields="positionsFields"
          :withIterator="true"
          withTotal="amount_payment"
          :orders="orders"
          @addRow="addRegistryPosition"
        />

        <div v-else class="text-center mt-3 h5 text-danger">
          По выбранным данным не найдено ни одного заказа. Пожалуйста проверьте
          правильность введенных данных.
        </div>
      </b-tab>
    </b-tabs>

    <b-modal id="confirm-delete" title="Удаление заявки" centered>
      <p class="m-0">Вы действительно хотите удалить данный реестр?</p>
      <template #modal-footer="{ hide }">
        <b-button @click="hide()">Нет</b-button>
        <b-button variant="danger" @click="deleteRegistry">Да</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";
import CreateTable from "@/components/Tables/Create";
import PageLoader from "@/components/UI/PageLoader";
import { debounce } from "debounce";
import axios from "axios";

export default {
  name: "RegistryForm",
  components: {
    PageLoader,
    CreateTable,
  },
  props: {
    edit: Boolean,
  },
  validations: {
    sendData: {
      provider_contr_agent_id: { required },
      provider_contract_id: { required },
      responsible_full_name: { required },
      responsible_phone: { required },
      comment: { required },
    },
    positions: {
      $each: {
        order_id: { required },
        payment_order_number: { required },
        payment_order_date: { required },
        amount_payment: { required },
        payment_type: { required },
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      currentRegistry: (state) => state.registries.currentRegistry,
      contrAgents: (state) => state.referenceBooks.contrAgents,
    }),
    ...mapGetters(["getContrAgentsOptions", "getProvidersContractsOptions"]),
    isProvider() {
      return this.$store.getters.isProvider;
    },
    getContrAgent() {
      return this.contrAgents.filter(
        (i) => i.id === this.user.contr_agent.id
      )[0];
    },
  },
  data: () => ({
    tabIndex: 0,
    sendData: {
      action: "draft",
      provider_contr_agent_id: null,
      contractor_contr_agent_id: null,
      provider_contract_id: null,
      responsible_full_name: "",
      responsible_phone: "",
      comment: "",
    },
    positionsFields: [
      {
        key: "order_id",
        label: "№ заказа",
        full: "Номер заказа",
        type: "select",
      },
      {
        key: "order_date",
        label: "Дата заказа",
        full: "Дата заказа",
        type: "disabled",
      },
      {
        key: "payment_order_number",
        label: "Номер ПП",
        full: "Номер ПП",
        type: "text",
      },
      {
        key: "payment_order_date",
        label: "Дата ПП",
        full: "Дата ПП",
        type: "date",
      },
      {
        key: "amount_payment",
        label: "Сумма платежа",
        full: "Сумма платежа",
        type: "number",
      },
      {
        key: "payment_type",
        label: "Вид платежа",
        full: "Вид платежа",
        type: "select",
      },
    ],
    positions: [
      {
        order_id: null,
        payment_order_number: null,
        payment_order_date: null,
        amount_payment: null,
        payment_type: null,
      },
    ],
    orders: [],
    count: 0,
    pageLoad: false,
  }),
  watch: {
    "sendData.provider_contr_agent_id"() {
      this.watchForSendData();
    },
    "sendData.provider_contract_id"() {
      this.watchForSendData();
    },
  },
  methods: {
    deleteRegistry() {
      if (this.edit) {
        this.$store
          .dispatch("deleteRegistry", this.$route.params.id)
          .then(() => {
            this.$bvToast.toast("Реестр платежей успешно удален", {
              toaster: "b-toaster-top-right",
              solid: true,
              appendToast: false,
              variant: "success",
            });
            this.$router.push("/registries");
          })
          .catch(() => {
            this.$bvToast.toast("Что-то пошло не так, попробуйте позднее", {
              toaster: "b-toaster-top-right",
              solid: true,
              appendToast: false,
              variant: "danger",
            });
          });
      } else {
        this.$router.push("/registries");
      }
    },
    createRegistry(save = false) {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$bvToast.toast("Заполните обязательные поля", {
          toaster: "b-toaster-top-right",
          solid: true,
          appendToast: false,
          variant: "danger",
        });
        return;
      }
      this.sendData.action = save ? "draft" : "approve";

      if (this.edit) {
        this.$store
          .dispatch("updateRegistry", {
            id: this.$route.params.id,
            data: { ...this.sendData, positions: this.positions },
          })
          .then(() => {
            this.sendSuccess("Успешно");
          })
          .catch((err) => {
            this.sendError(err);
          });
      } else {
        this.$store
          .dispatch("createRegistry", {
            ...this.sendData,
            positions: this.positions,
          })
          .then(() => {
            this.sendSuccess("Успешно");
          })
          .catch((err) => {
            this.sendError(err);
          });
      }
    },
    addRegistryPosition() {
      this.positions.push({
        order_id: null,
        payment_order_number: null,
        payment_order_date: null,
        amount_payment: null,
        payment_type: null,
      });
      this.count++;
    },
    sendSuccess(successText) {
      this.$bvToast.toast(successText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "success",
      });
      this.$router.push("/registries");
    },
    sendError(err) {
      const errText =
        err.response.status === 422
          ? "Проверьте правильность заполненных полей"
          : "Ошибка сервера. Попробуйте позднее.";
      this.$bvToast.toast(errText, {
        toaster: "b-toaster-top-right",
        solid: true,
        appendToast: false,
        variant: "danger",
      });
    },
    async getOrders(data) {
      let canSend = true;
      for (let key in data) {
        if (!data[key]) {
          canSend = false;
        }
      }
      if (canSend) {
        await this.$store
          .dispatch("getRegistrySearchOrders", data)
          .then((resp) => {
            this.orders = resp;
          });
      }
    },
    watchForSendData() {
      const data = {
        provider_contr_agent_id: this.sendData.provider_contr_agent_id,
        provider_contract_id: this.sendData.provider_contract_id,
        contractor_contr_agent_id: this.sendData.contractor_contr_agent_id,
      };
      this.getOrders(data);
    },
    getContrAgentsOptionsByQuery(search, loading) {
      if (search.length > 1) {
        loading(true);
        this.getContrAgentsOptionsSearch(search, loading, this);
      }
    },
    getContrAgentsOptionsSearch: debounce(async function (search, loading, vm) {
      axios
        .get("/references/contr_agents", { params: { name: search } })
        .then((r) =>
          vm.$store.commit("setReferenceBooks", {
            book: "contrAgents",
            data: r.data.data.data,
          })
        )
        .finally(() => loading(false));
    }, 800),
  },
  async mounted() {
    const id = this.$route.params.id;
    this.sendData.contractor_contr_agent_id = this.user.contr_agent.id;
    if (this.edit) {
      await this.$store.dispatch("getCurrentRegistry", id);
      for (let key in this.sendData) {
        this.sendData[key] = this.currentRegistry[key];
      }
      this.positions = this.currentRegistry.positions.data;
    }
    this.pageLoad = true;
  },
};
</script>

<style
    lang="sass"
    scoped
>
</style>
