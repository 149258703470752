<template>
    <RegistryForm :edit="true"/>
</template>

<script>
import RegistryForm from '@/components/Registries/RegistryForm'

export default {
    name: 'RegistriesItemEdit',
    components: {
        RegistryForm
    },
    computed: {
        currentRegistry () {
            return this.$store.state.registries.currentRegistry
        },
        breadcrumbs () {
            return [
                {title: 'Главная', link: '/'},
                {title: 'Заказ ПО', link: '/'},
                {title: 'Перечень реестров платежей по договорам подряда', link: '/registries'},
                {title: `Реестр платежей № ${this.currentRegistry.number} от ${this.$options.filters.parseDate(this.currentRegistry.date)}`, link: `/registries/${this.$route.params.id}`},
                {title: 'Редактирование'},
            ]
        }
    }
}
</script>
